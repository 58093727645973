import React from 'react';
import './pricecall.css'
var apikey={
    key:'3e6d3f73-304d-4100-ae0e-a2a1a9053e72'
  }
class Pricecall extends React.Component{
        
        constructor(){
            super();
            this.state = {
                users: null
            }
        }
        componentDidMount(){
            fetch('https://cors-anywhere.herokuapp.com/https://pro-api.coinmarketcap.com/v1/cryptocurrency/listings/latest?limit=100&CMC_PRO_API_KEY='+apikey.key).then((resp) => {
                resp.json().then((result) => {
                    console.warn(result)
                    this.setState({users:result.data})
                })
            })
        }
        render(){
            return(
                <div className='full'>
                    <table className='table'>
                        <thead>
                            <th>Rank</th>
                            <th>Name</th>
                            <th>Symbol</th>
                            <th>Price</th>
                            <th>1H %</th>
                            <th>24h %</th>
                            <th>Market Cap</th>
                        </thead>
                    
                    {   
                        this.state.users ?
                        this.state.users.map((item,i) =>
                                <tr>
                                    <td className='common'>{item.cmc_rank}</td>
                                    <td className='common'>{item.name}</td>
                                    <td className='common'>{item.symbol}</td>
                                    <td className='common'>{item.quote.USD.price}</td>
                                    {item.quote.USD.percent_change_1h >0 ? <td className='green'>{item.quote.USD.percent_change_1h}</td> : <td className='red'>{item.quote.USD.percent_change_1h}</td>}
                                    {item.quote.USD.percent_change_24h >0 ? <td className='green'>{item.quote.USD.percent_change_24h}</td> : <td className='red'>{item.quote.USD.percent_change_24h}</td>}
                                    {/* <td className='common'>{item.quote.USD.percent_change_24h}</td> */}
                                    <td className='common'>{item.quote.USD.market_cap}</td>
                                </tr>
                            
                            /* <p className='rows'>{i}  {item.name}  {item.symbol}  {item.quote.USD.price} </p> */
                        )
                        :
                        null
                    }
                    </table>
                </div>
            )
        }
}

export default Pricecall;